import React from 'react'

const SvgFilm06 = props => (
  <svg viewBox='0 0 41.09 45' {...props}>
    <defs>
      <style>{'.film_06_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='film_06_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_06_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_06_svg__cls-1'
          d='M20.54 45L0 33.77V11.23L20.54 0l20.55 11.23v22.54zM2 32.62l18.54 10.16 18.59-10.16V12.38L20.54 2.22 2 12.38z'
        />
        <path
          className='film_06_svg__cls-1'
          d='M9.78 14.67v15.74l12.39 6.68v-2.23l-10.43-5.42V15.75l-1.96-1.08z'
        />
        <path
          className='film_06_svg__cls-1'
          d='M14.35 10.76V26.5l12.39 6.67v-2.22L16.3 25.53V11.94l-1.95-1.18z'
        />
        <path
          className='film_06_svg__cls-1'
          d='M18.91 7.54v16.05l13.05 7v-15zM30 27.7l-9.13-5.07V10.45L30 16.54z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm06
