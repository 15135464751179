import React from 'react'

const SvgFilm21 = props => (
  <svg viewBox='0 0 46 46' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M43.67 30.48a20.52 20.52 0 00.71-5.35A20.89 20.89 0 0028.79 5a5.79 5.79 0 00-11.49.15 21 21 0 00-15.1 20 20.67 20.67 0 00.63 5A5.62 5.62 0 00.92 32a5.7 5.7 0 001.74 7.92 5.81 5.81 0 003.12.9 5.91 5.91 0 002.82-.72 21.19 21.19 0 0029.19.2 5.84 5.84 0 007.29-2.11 5.68 5.68 0 00-1.41-7.71zM23.06 2a3.73 3.73 0 110 7.46 3.76 3.76 0 01-3.78-3.73A3.76 3.76 0 0123.06 2zM3.75 38.2a3.7 3.7 0 01-1.13-5.15 3.78 3.78 0 015.21-1.12A3.7 3.7 0 019 37.08a3.79 3.79 0 01-5.25 1.12zm6.43.6a6.28 6.28 0 00.48-.64 5.7 5.7 0 00-1.74-7.92 5.8 5.8 0 00-4.19-.8A18.8 18.8 0 0117.46 7.18 5.8 5.8 0 0028.69 7a18.75 18.75 0 0113.13 22.56A5.83 5.83 0 0035.34 32a5.69 5.69 0 00.75 7.12 19.13 19.13 0 01-25.91-.29zm33.2-1.72a3.79 3.79 0 01-5.21 1.12A3.71 3.71 0 0137 33.05a3.78 3.78 0 015.21-1.12 3.7 3.7 0 011.17 5.15z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgFilm21
