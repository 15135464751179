import React from 'react'

const SvgFilm19 = props => (
  <svg viewBox='0 0 40 40' {...props}>
    <defs>
      <style>{'.film_19_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='film_19_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_19_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_19_svg__cls-1'
          d='M23 0H0v13h23zm-2 11H2V2h19zM17 40h23V27H17zm2-11h19v9H19zM27 0v23h13V0zm11 21h-9V2h9zM0 40h13V17H0zm2-21h9v19H2z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm19
