import React from 'react'

const SvgFilm11 = props => (
  <svg viewBox='0 0 53.57 49.99' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M53.57 34.16c-1.41 2.21-2.68 4.52-4.26 6.59a18.58 18.58 0 01-16 7.82c-6.47-.16-13-.06-19.43 0-2 0-2.68-.64-2.63-2.66.12-5.13.08-10.26 0-15.39 0-1.7.58-2.34 2.31-2.32 5.43.07 10.86 0 16.3 0 5.79 0 7.05.9 8.78 6.61 1-1.4 2-2.55 2.72-3.8 3.78-6.2 8.31-6.3 12.2-.25v3.33M13.86 46.1h2.42c5.3 0 10.6-.24 15.88 0 9.12.48 14.55-4.41 18.39-11.85a3.46 3.46 0 00-1.28-4.88c-1.71-1-3.62-.47-4.86 1.38-1 1.5-1.87 3.1-3 4.53a8.59 8.59 0 01-7.57 3.61c-3.75-.13-7.52 0-11.28-.08-.72 0-2-.64-2-1.09-.17-1.43 1.13-1.26 2.08-1.27h10.45c2.31 0 3.23-1.32 2.41-3.48-.67-1.74-2.12-2.26-3.86-2.26H14.93a10.1 10.1 0 00-1.07.15V46.1M44.36 0A11.72 11.72 0 0145 2.73c0 2.34.7 5.51-.52 6.78s-4.42.6-6.75.62H17.46c-1.79 0-2.56-.49-2.4-2.36.22-2.6.3-5.21.44-7.82h28.86M29.92 2.7H18.19v4.35h11.73V2.7M0 27.08h9.54c.1.22.25.4.25.58v21.75a2.16 2.16 0 01-.18.58H0V27.08m7.46 4.39c-1.1-.86-1.8-1.81-2.31-1.73-.72.13-1.3 1-1.95 1.6.61.61 1.14 1.54 1.85 1.7.52.12 1.27-.78 2.41-1.57M30 22.35c-4.26 0-8.51-.05-12.76 0-1.65 0-2.25-.58-2.17-2.21.11-2 .09-4 0-6-.06-1.47.57-2 2-1.94h25.94c1.56 0 2.12.58 2.07 2.09-.08 2-.08 4 0 6 .06 1.55-.6 2.07-2.11 2.05-4.32-.06-8.65 0-13 0m0-7.64H18.22v4.35h11.71v-4.34m12.6 9.81l-3.39 3.93c-2.66-3.56-6.55-3.52-10.51-3.45-4.35.08-8.71 0-13.07 0v-.5h27'
        fill='#13cb75'
        fillRule='evenodd'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgFilm11
