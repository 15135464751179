import React from 'react'

const SvgFilm04 = props => (
  <svg viewBox='0 0 28.89 40' {...props}>
    <defs>
      <style>{'.film_04_svg__cls-2{fill:#13cb75}'}</style>
    </defs>
    <g id='film_04_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_04_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          d='M7.66 40c-.49 0-.63-.43-.7-.72a10.28 10.28 0 00-1.4-3.43 19.56 19.56 0 01-1.39-3c-.49-1.15-1-3.65-1.47-4.58a12.35 12.35 0 00-1.32-1.86 3.07 3.07 0 012.72-.79 3.67 3.67 0 012.16 1.5v-7.87c0-1.14.35-2.86 1.75-2.86s1.74 1.72 1.74 2.86v4.3a1.64 1.64 0 011.95-1.36A2.1 2.1 0 0113.24 24a1.61 1.61 0 012.09-.79 2 2 0 011.19 1.93 1.55 1.55 0 011.6-.86c1.61.36 1.4 1.86 1.4 2.86v2.94a14 14 0 01-1.68 6.51 9.08 9.08 0 00-1.18 2.93.67.67 0 01-.66.48z'
          fillRule='evenodd'
          fill='#13cb75'
        />
        <path
          className='film_04_svg__cls-2'
          d='M6.55 4.73a.62.62 0 00-.61-.62.61.61 0 00-.61.62v1.65a.61.61 0 00.61.62.62.62 0 00.61-.62zM20.07 19.71h-1.48a.63.63 0 000 1.25h1.48a.63.63 0 000-1.25zM14.74 9.33H1a1 1 0 00-1 1v10.21a1 1 0 001 1h4.66V19.5H2v-8.1h11.7v8.1h-3.4v2.08h4.44a1 1 0 001-1V10.36a1 1 0 00-1-1.03zM18.59 1.25h1.48a.62.62 0 00.61-.63.61.61 0 00-.61-.62h-1.48a.61.61 0 00-.6.62.62.62 0 00.6.63zM9.71 1.25h1.48a.62.62 0 00.61-.63.61.61 0 00-.61-.62H9.71a.61.61 0 00-.61.62.62.62 0 00.61.63zM14.15 1.25h1.48a.62.62 0 00.61-.63.61.61 0 00-.61-.62h-1.48a.61.61 0 00-.61.62.62.62 0 00.61.63zM5.94 2.07a.62.62 0 00.61-.62v-.2h.2a.62.62 0 00.6-.63.61.61 0 00-.6-.62h-.81a.61.61 0 00-.61.62v.83a.61.61 0 00.61.62zM28.29 4.11a.61.61 0 00-.61.62v1.65a.61.61 0 101.21 0V4.73a.61.61 0 00-.6-.62zM23.51 10.63h.1a.46.46 0 00.29-.38l.63-5.18a.48.48 0 00-.14-.39.42.42 0 00-.39-.14l-5 1a.46.46 0 00-.34.33.46.46 0 00.12.46l1.54 1.47-3.44 3.77a.94.94 0 000 1.3.91.91 0 00.72.24.88.88 0 00.55-.29L21.62 9l1.53 1.47a.44.44 0 00.36.16zM28.29 9a.61.61 0 00-.61.62v1.68a.61.61 0 101.21 0V9.66a.61.61 0 00-.6-.66zM28.29 0h-.81a.61.61 0 00-.61.62.62.62 0 00.61.63h.2v.2a.61.61 0 101.21 0V.62a.61.61 0 00-.6-.62zM28.29 14a.61.61 0 00-.61.62v1.64a.61.61 0 101.21 0v-1.68a.61.61 0 00-.6-.58zM24.51 19.71H23A.63.63 0 0023 21h1.48a.63.63 0 000-1.25zM28.29 18.88a.61.61 0 00-.61.62v.21h-.2a.61.61 0 00-.61.62.61.61 0 00.61.62h.81a.61.61 0 00.6-.62v-.83a.61.61 0 00-.6-.62zM24.51 0H23a.61.61 0 00-.6.62.62.62 0 00.6.63h1.48a.62.62 0 00.61-.63.61.61 0 00-.58-.62z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm04
