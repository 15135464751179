import React from 'react'

const SvgFilm20 = props => (
  <svg viewBox='0 0 38 44' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <g data-name='\u56FE\u5C42 1'>
        <path
          data-name='bgXMLID'
          d='M0 0v23.9C0 36 19 44 19 44s19-8 19-20V0zm36 24c0 9.2-13.6 16.2-17 17.8-3.4-1.6-17-8.6-17-17.9V2h34zm-9-7h-1v-2a6.9 6.9 0 00-7-7 6.9 6.9 0 00-7 7v2h-1v12h16zm-13-2a4.83 4.83 0 015-5 4.83 4.83 0 015 5v2H14zm11 12H13v-8h12z'
          fill='#13cb75'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm20
