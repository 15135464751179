import React from 'react'

const SvgFilm17 = props => (
  <svg viewBox='0 0 40 40' {...props}>
    <defs>
      <style>{'.film_17_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='film_17_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_17_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_17_svg__cls-1'
          d='M0 0v9h40V0zm38 7H2V2h36zM0 21h40v-9H0zm2-7h36v5H2zM0 33h18v5H0v2h40v-2H22v-5h18v-9H0zm2-7h36v5H2z'
        />
        <path className='film_17_svg__cls-1' d='M5 3h3v3H5zM5 15h3v3H5zM5 27h3v3H5z' />
      </g>
    </g>
  </svg>
)

export default SvgFilm17
