import React from 'react'

const SvgFilm03 = props => (
  <svg viewBox='0 0 66 66' {...props}>
    <defs>
      <style>{'.film_03_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='film_03_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_03_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_03_svg__cls-1'
          d='M19 31.88c1.4 0 1.74.82.75 1.81l-1.86 1.87a.08.08 0 000 .11l1.77 1.77 1.77 1.78a.08.08 0 00.11 0l1.85-1.86c1-1 1.8-.66 1.8.75v2.63a.08.08 0 00.08.08h5a.08.08 0 00.08-.08v-2.63c0-1.41.81-1.74 1.79-.75L34 39.22a.08.08 0 00.11 0l1.77-1.78 1.76-1.77a.08.08 0 000-.11l-1.85-1.87c-1-1-.65-1.81.75-1.81h2.62a.07.07 0 00.07-.08v-5a.07.07 0 00-.07-.08h-2.65c-1.39 0-1.73-.82-.74-1.81L37.62 23a.09.09 0 000-.12l-1.76-1.77-1.77-1.78a.08.08 0 00-.11 0l-1.86 1.86c-1 1-1.79.66-1.79-.75v-2.6a.08.08 0 00-.08-.08h-5a.08.08 0 00-.08.08v2.63c0 1.41-.81 1.75-1.8.75l-1.85-1.86a.08.08 0 00-.11 0l-1.77 1.78-1.77 1.77a.09.09 0 000 .12l1.86 1.86c1 1 .65 1.81-.75 1.81h-2.62a.08.08 0 00-.08.08v5a.08.08 0 00.08.08zm8.94-7.62A5 5 0 1123 29.21a4.94 4.94 0 014.92-4.95zM40.07 17.33c.66 0 .82.39.35.86l-.88.89v.05l.84.85.85.85h.05l.88-.89c.47-.48.86-.31.86.36v1.25h2.38V20.3c0-.67.38-.84.86-.36l.88.89h.05l.85-.85.84-.85v-.05l-.89-.89c-.47-.47-.31-.86.36-.86h1.25v-2.4h-1.17c-.67 0-.83-.38-.36-.86l.93-.97-.85-.85-.84-.85h-.05l-.88.89c-.48.48-.86.32-.86-.35v-1.31h-2.46v1.26c0 .67-.39.83-.86.35l-.88-.89h-.05l-.85.85-.84.85.88.89c.47.48.31.86-.35.86h-1.29v2.4zm4.26-3.64A2.36 2.36 0 1142 16.05a2.36 2.36 0 012.33-2.36z'
        />
        <path
          className='film_03_svg__cls-1'
          d='M62.7 0H3.3A3.35 3.35 0 000 3.39v45.25A3.35 3.35 0 003.3 52h28.05v10.75H22V66h22v-3.25h-9.35V52H62.7a3.35 3.35 0 003.3-3.4V3.39A3.35 3.35 0 0062.7 0zm.11 48.82H3.19V3.06h59.62z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm03
