import React from 'react'

const SvgFilm07 = props => (
  <svg viewBox='0 0 40 40' {...props}>
    <defs>
      <style>{'.film_07_svg__cls-1{fill:#14cc76}'}</style>
    </defs>
    <g id='film_07_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_07_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_07_svg__cls-1'
          d='M20.17 30h-.93a18 18 0 01-15.31-9.55l-.07-.17a1.39 1.39 0 010-.23 1.42 1.42 0 01-.05-.36 1.13 1.13 0 010-.32 2 2 0 01.05-.24v-.11l.1-.17a18 18 0 0115.28-9.48h.93a18 18 0 0115.29 9.51 1.68 1.68 0 01.17.85 1.64 1.64 0 01-.17.77A18 18 0 0120.17 30zM5.63 19.71a16 16 0 0013.59 8.42h.93a16 16 0 0013.6-8.44 16 16 0 00-13.6-8.44h-.93a16 16 0 00-13.56 8.36v.08zm28.14-.35z'
        />
        <path
          className='film_07_svg__cls-1'
          d='M19.69 13.25a5.78 5.78 0 00-5.62 5.92 5.78 5.78 0 005.62 5.92 5.79 5.79 0 005.63-5.92 5.79 5.79 0 00-5.63-5.92zm0 4.22a1.61 1.61 0 11-1.6-1.69 1.65 1.65 0 011.6 1.69zm0 5.93a4.09 4.09 0 01-4-3.64 3.15 3.15 0 002.36 1.1 3.31 3.31 0 003.21-3.39 3.48 3.48 0 00-1-2.48 4.25 4.25 0 01-.57 8.41zM0 0V9.38h1.88v-7.5h7.5V0H0zM38.13 0h-7.5v1.88h7.5v7.5H40V0h-1.87zM1.88 30.63H0V40H9.38v-1.87h-7.5v-7.5zM38.13 38.13h-7.5V40H40V30.63h-1.87v7.5z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm07
