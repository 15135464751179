import React from 'react'

const SvgFilm09 = props => (
  <svg viewBox='0 0 76 80' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M29.81 7.83c-1.72 0-3.45 1.75-3 3.5a3.74 3.74 0 003.46 3.5 3.73 3.73 0 003.45-3.5c-.45-2.19-1.72-3.5-3.91-3.5zm44.94 22.74C73 23.57 69.14 19.64 64 19.64h-4.8V12.2c0-5.69-4.32-10.5-10.8-11.37-6-.88-11.67-1.31-18.59 0C20.74 2.58 19 6.51 19 12.2v7h-4.74c-6.91.44-11.67 4.37-13 10.93-1.73 7.44-1.73 12.25 0 19.25S6.91 60.32 13 60.32h3.89v7c0 5.25 3.89 9.19 10.37 11.38a38.33 38.33 0 009.47 1.3 38.25 38.25 0 009.5-1.31c8.65-2.63 10.37-7.44 10.37-11.38v-7h6.92c6.91 0 9.07-5.25 10.8-10.5 2.16-5.68 2.16-11.81.43-19.24zm-57.9 19.25v6.12H13c-3.89 0-6.05-2.62-7.34-7.43C3.89 42 3.89 38 5.62 31a9.08 9.08 0 019.07-7.44h24.63a2 2 0 002.16-2.18 2 2 0 00-2.16-2.19h-16v-7c0-3.94.44-6.12 6.92-7a57.51 57.51 0 0117.28 0c3.46.44 6.91 3.06 6.91 7v17.07a6.86 6.86 0 01-6.91 7H30.25c-7.35.44-13.4 6.56-13.4 13.56zm53.58-1.31c-2.16 6.12-3.46 7.87-6.91 7.87H36.73a2.19 2.19 0 000 4.37h15.55v7c0 3.06-2.59 5.69-7.34 7a26.33 26.33 0 01-16.42 0c-3.46-.87-7.35-3.06-7.35-7V50.26a9.48 9.48 0 019.08-9.19H48A11.52 11.52 0 0059.2 29.7V24H64c2.16 0 5.19.88 6.91 7.44 1.25 7.01 1.25 12.25-.48 17.07zM46.67 71.25a3.5 3.5 0 000-7 3.5 3.5 0 000 7z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgFilm09
