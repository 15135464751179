import React from 'react'

const SvgFilm01 = props => (
  <svg viewBox='0 0 65.96 66' {...props}>
    <defs>
      <style>{'.film_01_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='film_01_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_01_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_01_svg__cls-1'
          d='M64.31 0a55.83 55.83 0 00-22.67 4.33A38.85 38.85 0 0028 14.18c-.69.78-1.33 1.57-1.92 2.36a17 17 0 00-8.65 3.36 36.87 36.87 0 00-7.3 7.1 62.74 62.74 0 00-6.65 10.21A1.64 1.64 0 005 39.59a1.67 1.67 0 001.17-.47A22.28 22.28 0 0110.4 36a22.91 22.91 0 019.77-3c1 2.68 4 5.88 5.46 7.32s4.64 4.42 7.31 5.47a22.92 22.92 0 01-3 9.77 22.21 22.21 0 01-3.08 4.28A1.65 1.65 0 0026.7 62a1.62 1.62 0 001.34.69 1.57 1.57 0 00.73-.17A63.21 63.21 0 0039 55.86a37.08 37.08 0 007.11-7.34 17.06 17.06 0 003.36-8.66c.79-.59 1.58-1.23 2.36-1.92a38.93 38.93 0 009.85-13.61A56.25 56.25 0 0066 1.65V0h-1.69zM20 29.74a25.69 25.69 0 00-9.72 2.62c3.15-4.56 8-10.22 13.37-12A31.42 31.42 0 0020 29.74zm13.65 26A25.64 25.64 0 0036.23 46a31.52 31.52 0 009.38-3.65c-1.79 5.43-7.44 10.23-12 13.38zm16-20.26c-6.86 6.05-14 7.42-15 7.42a6 6 0 01-2.5-1.3A34.55 34.55 0 0128 38a34.58 34.58 0 01-3.57-4.17c-1.28-1.82-1.3-2.49-1.3-2.5 0-1 1.37-8.13 7.42-15 7.24-8.21 18.32-12.7 32.13-13-.38 13.8-4.86 24.89-13.07 32.14z'
        />
        <path
          className='film_01_svg__cls-1'
          d='M16 42.74c-5.45 0-10.78 7.08-15.85 21a1.65 1.65 0 00.38 1.73 1.68 1.68 0 001.12.53 1.84 1.84 0 00.56-.1C15 61.27 22 56.39 23.09 51.42A6.68 6.68 0 0021 45a7 7 0 00-5-2.26zm3.91 7.95c-.25 1.15-1.29 3-4.81 5.43a55 55 0 01-10.6 5.36 51.48 51.48 0 015.9-11.31c2-2.71 3.92-4.17 5.6-4.17a3.8 3.8 0 012.67 1.33 3.53 3.53 0 011.19 3.36zM44.52 29.7a8.25 8.25 0 10-8.25-8.25 8.26 8.26 0 008.25 8.25zm0-13.21a4.95 4.95 0 11-5 5 5 5 0 015-5z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm01
