import React from 'react'

const SvgFilm14 = props => (
  <svg viewBox='0 0 55.03 50.02' {...props}>
    <defs>
      <style>{'.film_14_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='film_14_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_14_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_14_svg__cls-1'
          d='M23.05 35a2.75 2.75 0 001.79-3.43 2.6 2.6 0 00-.21-.51c-2.57-3.94-.84-7.16 2.33-8.85 0 0-9.14 1.54-7.41 10.68a3.8 3.8 0 00.18.54A2.76 2.76 0 0023.05 35z'
        />
        <path
          className='film_14_svg__cls-1'
          d='M33.67 32.54a2.69 2.69 0 00-3.82.24 4.29 4.29 0 00-.33.45c-2 4.24-5.67 4.42-8.74 2.56 0 0 6 7.07 12.89.81l.39-.42a2.74 2.74 0 00-.39-3.64z'
        />
        <path
          className='film_14_svg__cls-1'
          d='M29.88 22.58c-.09 0-.48-.09-.57-.12a2.7 2.7 0 00-2.89 2.2 2.75 2.75 0 002.15 3.22 3.31 3.31 0 00.53.06c4.69-.42 6.66 2.64 6.63 6.25 0 0 3-8.87-5.85-11.61z'
        />
        <path
          className='film_14_svg__cls-1'
          d='M54.79 40.4a6.91 6.91 0 00-3.2-4.2 6.81 6.81 0 00-8.7 1.59l-3.7-1.85A12.66 12.66 0 0028.94 18v-4.28a6.86 6.86 0 10-2.31 0V18a12.68 12.68 0 00-10.25 17.94l-4.24 2.75a6.89 6.89 0 00-3.5-2.27 6.77 6.77 0 00-5.2.69 6.93 6.93 0 001.64 12.67 6.78 6.78 0 005.21-.69 6.93 6.93 0 003-8.39l4.23-2.7A12.48 12.48 0 0038 38l3.7 1.84a7 7 0 00-.19 4.16 6.88 6.88 0 0013.28-3.6zm-43.52 3.88a4.55 4.55 0 01-5.58 3.25 4.59 4.59 0 011.17-9 4.38 4.38 0 011.19.16 4.51 4.51 0 012.77 2.14 4.65 4.65 0 01.45 3.48zm12-37.37a4.56 4.56 0 114.55 4.6 4.58 4.58 0 01-4.55-4.6zM27.79 41a10.35 10.35 0 1110.26-10.36A10.32 10.32 0 0127.79 41zm24.32 3.5a4.55 4.55 0 01-8.35-1.11A4.58 4.58 0 0147 37.76a4.46 4.46 0 013.46.46 4.61 4.61 0 011.67 6.27z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm14
