import React from 'react'
import ScaleSwiper from './scale-swiper'
import Media from '@renderbus/common/theme/media'

import team1 from '../../../images/film-tv-rendering/team1.png'
import team3 from '../../../images/film-tv-rendering/team3.png'

import styled from 'styled-components'
import { color, typography, hideSMDown, hideSMUp } from '@renderbus/common/theme'

const TeamSwiperContainer = styled.div`
  padding-top: 20px;
  height: auto;
`
const ScaleSwiperHiddenOnPhone = styled.div`
  ${hideSMDown};
`
const TeamSwiperText = styled.p`
  margin: 30px auto;
  max-width: 900px;
  font-size: ${typography.textSmall};
  color: ${color.prompt};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.text};
  }
`
const SwiperImgBox = styled.div`
  img {
    width: 100%;
  }
  video {
    width: 100%;
  }
`
const PhoneImgBox = styled.div`
  position: relative;
  margin: 20px 0;
  img {
    width: 100%;
  }
  video {
    width: 100%;
  }
`
const TeamContainerOnPhone = styled.div`
  ${hideSMUp};
`
const TeamInfoBox = styled.div`
  position: absolute;
  bottom: 0;
  padding: 10px;
  width: 100%;
  color: white;
  background: rgba(0, 0, 0, 0.6);
`
const TeamInfoTitle = styled.h3`
  font-weight: 500;
`

const teamSwiperTextArray = [
  '我们的专家团队拥有好莱坞多年的CGI电影制作管理经验，作为全球数万工作室采用的渲染服务提供商，一年内平台处理的电影帧数超过2000万帧。我们的TD团队拥有顶尖CG制作公司的从业经验,在CG渲染优化、高性能计算方面投入了大量的研究资源。',
  '大多数人认为瑞云渲染=渲染农场，但是以瑞云渲染强大的技术背景作为支持，瑞云渲染的服务已经延展到项目管理、流程整合、提供解决方案、研究及其他。我们拥有专属的大型分配系统，专有的高速多站点同步传输引擎，我们不仅限于渲染，而是专注于更多围绕娱乐产业相关的技术。',
  '瑞云拥有超过40人的研发团队，我们在高性能集群系统、海量存储以及高吞吐量网络io方面，都有深入的研究。我们的目标是通过背后复杂的技术，为全球CG行业用户提供一个简单易用的渲染平台。',
]

class TeamSwiper extends React.PureComponent {
  state = { slideIndex: 1 }
  swiperVideoRef = null
  phoneVideoRef = null
  slideChange(index) {
    if (index === this.slideIndex) return
    this.setState({
      slideIndex: index,
    })
    if (this.swiperVideoRef) {
      this.swiperVideoRef.pause()
    }
    if (this.phoneVideoRef) {
      this.phoneVideoRef.pause()
    }
  }
  setSwiperVideoRef = ref => {
    this.swiperVideoRef = ref
  }
  setPhoneVideoRef = ref => {
    this.phoneVideoRef = ref
  }
  render() {
    const { slideIndex } = this.state
    return (
      <TeamSwiperContainer>
        <TeamSwiperText>{teamSwiperTextArray[slideIndex]}</TeamSwiperText>
        <ScaleSwiperHiddenOnPhone>
          <ScaleSwiper
            initialSlide={slideIndex}
            onSlideChange={index => {
              this.slideChange(index)
            }}
          >
            <SwiperImgBox>
              <img src={team1} alt='team1'></img>
            </SwiperImgBox>
            <SwiperImgBox>
              <video
                src='https://renderbus-assets.oss-cn-hangzhou.aliyuncs.com/video/Team-Renderbus-2021.mp4'
                allowFullScreen={true}
                controls={true}
                title='team'
                ref={this.setSwiperVideoRef}
              />
            </SwiperImgBox>
            <SwiperImgBox>
              <img src={team3} alt='team3'></img>
            </SwiperImgBox>
          </ScaleSwiper>
        </ScaleSwiperHiddenOnPhone>
        <TeamContainerOnPhone>
          <PhoneImgBox>
            <video
              src='https://renderbus-assets.oss-cn-hangzhou.aliyuncs.com/video/Team-Renderbus-2021.mp4'
              allowFullScreen={true}
              controls={true}
              title='team'
              ref={this.setPhoneVideoRef}
            />
          </PhoneImgBox>
          <PhoneImgBox>
            <img src={team3} alt='team3'></img>
            <TeamInfoBox>
              <TeamInfoTitle>研发团队</TeamInfoTitle>
              <p>{teamSwiperTextArray[2]}</p>
            </TeamInfoBox>
          </PhoneImgBox>
          <PhoneImgBox>
            <img src={team1} alt='team1'></img>
            <TeamInfoBox>
              <TeamInfoTitle>技术服务团队 </TeamInfoTitle>
              <p>{teamSwiperTextArray[0]}</p>
            </TeamInfoBox>
          </PhoneImgBox>
        </TeamContainerOnPhone>
      </TeamSwiperContainer>
    )
  }
}

export default TeamSwiper
