import React from 'react'

const SvgFilm13 = props => (
  <svg viewBox='0 0 68.75 50' {...props}>
    <defs>
      <style>{'.film_13_svg__cls-1{fill:#13cb75;fill-rule:evenodd}'}</style>
    </defs>
    <g id='film_13_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_13_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_13_svg__cls-1'
          d='M53 8.09a21.36 21.36 0 00-31.68-2 20.6 20.6 0 00-6.19 14.17 15 15 0 00-10.7 4.38 14.65 14.65 0 000 21A15.08 15.08 0 0015.08 50h15.21a2.38 2.38 0 100-4.75H15.08a10.28 10.28 0 01-7.25-3 10 10 0 010-14.25 10.32 10.32 0 017.25-3h2.5a2.46 2.46 0 001.79-.78 2.37 2.37 0 00.63-1.8c-.05-.55-.08-1.08-.08-1.58a15.81 15.81 0 014.77-11.37A16.45 16.45 0 0149.15 11a2.4 2.4 0 001.91.92 2.35 2.35 0 001.47-.5 2.31 2.31 0 00.92-1.57A2.34 2.34 0 0053 8.09z'
        />
        <path
          className='film_13_svg__cls-1'
          d='M65.53 26.17C57.63 16.84 42.24 14 33.7 23.94c-4.63 5.41.92 5.59 4.81 4.42s6.32 2.24 2.43 4.73c-14.14 9.06.64 15.52 11.15 16.47 13.85 1.25 21.34-14.06 13.44-23.39m-22.86-1.24a2.69 2.69 0 01-.81-3.9 3.14 3.14 0 014.22-.44 2.7 2.7 0 01.81 3.9 3.14 3.14 0 01-4.22.44m7.78.81A3.73 3.73 0 0153 21.39a3.17 3.17 0 014 2.49 3.73 3.73 0 01-2.51 4.35 3.16 3.16 0 01-4-2.49m-6.7 17.37c-1.93-.9-2.77-2.91-1.87-4.48A4 4 0 0147 37.42c1.93.9 2.77 2.91 1.86 4.48s-3.2 2.11-5.14 1.21m16-12.21a2.42 2.42 0 01.53-3.41 2.52 2.52 0 013.5.19 2.42 2.42 0 01-.54 3.4 2.5 2.5 0 01-3.49-.18m2 9.77c-1.26-.65-1.51-2.43-.56-4s2.75-2.28 4-1.64 1.51 2.42.56 4-2.75 2.27-4 1.64'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm13
