import styled from 'styled-components'
import Media from '@renderbus/common/theme/media'
import Img from 'gatsby-image'
import { H1, H2, Flex, H3 } from '@renderbus/common/components'
import {
  typography,
  hideSMDown,
  color,
  fadeIn,
  transition,
  spacing,
  mbSize,
} from '@renderbus/common/theme'

import borderImage from '../images/film-tv-rendering/border.png'
import { Link } from 'gatsby'

export const BannerContainer = styled.div`
  position: relative;
  padding-top: 19.56vh;
  height: 100vh;
  text-align: center;
  color: white;
`
export const BannerTitle = styled(H1)`
  font-size: 60px;
  text-align: center;
  p {
    margin: ${spacing.small} auto;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(60)};
    margin: 0;
  }
`
export const MobileBr = styled.br`
  display: none;
  ${Media.lessThan(Media.small)} {
    display: block;
  }
`
export const PcBr = styled.br`
  ${hideSMDown};
`
export const PlayIconBox = styled.div`
  margin: 39px auto 0;
  text-align: center;
  cursor: pointer;
  width: fit-content;
  font-size: 24px;
  img {
    width: 45px;
    margin-right: 10px;
  }
  &:hover {
    color: #13cb75;
  }
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(36)};
    margin: ${mbSize(60)} auto 0;
    img {
      width: ${mbSize(60)};
      margin-right: ${mbSize(16)};
    }
  }
`

export const WarpperContainer = styled.div`
  padding: 100px 0;
  background: ${p => p.lighter && color.panel};
  ${Media.lessThan(Media.small)} {
    padding: 50px 0;
  }
`
export const WrapperTitle = styled(H2)`
  font-size: 40px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h2};
  }
`
export const PerformanceContainer = styled(Flex)`
  margin: 103px auto 0px;
  text-align: center;
  justify-content: space-around;
  align-items: flex-start;
  ${Media.lessThan(Media.small)} {
    margin: 50px 0;
  }
`
export const PerformanceContent = styled.div`
  text-align: center;
  width: 300px;
  svg {
    height: 70px;
  }
  ${Media.lessThan(Media.small)} {
    width: auto;
  }
`
export const PerformanceTitle = styled(H3)`
  margin-top: 30px;
  font-size: 30px;
  font-weight: 500;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
  }
`
export const PerformanceInfo = styled.p`
  color: ${color.prompt};
  font-size: ${typography.textSmall};
  ${hideSMDown};
`

export const AutoContainer = styled(Flex)`
  margin-top: 30px;
`
export const AutoImgBox = styled(Img)`
  width: 50%;
  max-height: 500px;
  img {
    max-width: 557px;
  }
  picture img {
    animation: ${fadeIn} 3000ms;
  }
  ${hideSMDown};
`
export const AutoHalfBox = styled(Flex)`
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 500px;
  ${Media.lessThan(Media.small)} {
    width: 100%;
  }
`
export const AutoIconRow = styled(Flex)`
  padding-left: 10px;
  ${Media.lessThan(Media.small)} {
    margin-top: 30px;
  }
  ${({ active }) =>
    active &&
    `
    ${Media.greaterThan(Media.small)} {
      ${AutoIconBox} {
        border: 2px solid ${color.primary};
        border-image: url(${borderImage}) 2;
        opacity: 1;
      }
    }
  `}
`
export const AutoIconBox = styled(Flex)`
  width: 60px;
  margin-right: 10px;
  flex-shrink: 0;
  height: 70px;
  width: 70px;
  opacity: 1;
  svg {
    width: 30px;
  }
  ${Media.greaterThan(Media.small)} {
    opacity: 0.5;
    transition: ${transition('opacity', 1000)};
    ${AutoIconRow}:hover & {
      border: 2px solid ${color.primary};
      border-image: url(${borderImage}) 2;
      opacity: 1;
    }
  }
`
export const AutoIconContent = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
`
export const AutoIconTitle = styled(H3)`
  color: ${color.primary};
`
export const AutoIconSubTitle = styled.p`
  margin: 0;
  color: ${color.prompt};
`

export const PipelineContainer = styled(Flex)`
  margin-top: 100px;
  text-align: center;
  justify-content: space-around;
  align-items: stretch;
  ${Media.lessThan(Media.small)} {
    margin-top: 30px;
  }
`
export const PipelineContent = styled.div`
  width: 420px;
  margin: 10px;
  svg {
    height: 80px;
  }
`
export const PipelineTitle = styled(H3)`
  font-size: 30px;
  font-weight: 500;
  margin-top: 10px;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h3};
  }
`
export const PipelineInfo = styled.p`
  color: ${color.prompt};
  font-size: ${typography.textSmall};
`

export const ProcessContainer = styled(Flex)`
  margin-top: 100px;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    margin-top: 30px;
  }
`
export const ProcessBox = styled.div`
  width: 150px;
  svg {
    height: 50px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100px;
    margin-top: 20px;
  }
`
export const ProcessTitle = styled(H3)`
  margin-top: 20px;
  color: ${color.primary};
  font-weight: 500;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
  }
`

export const CustomizeContainer = styled(Flex)`
  margin-top: 50px;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
`
export const CustomizeBox = styled(Flex)`
  width: 160px;
  height: 180px;
  flex-direction: column;
  border: 1px solid #444;
  svg {
    height: 50px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100px;
    height: 130px;
    margin-top: 20px;
  }
`
export const CustomizeTitle = styled.p`
  margin: 30px 0 0;
  color: white;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textSmall};
  }
`
export const AdvertisingSpaceWrap = styled(Link)`
  display: block;
  background: rgb(51, 51, 51);
  margin-top: ${p => p.marginTop || 'unset'};
  img {
    width: 100%;
  }
`
export const RenderPlatform = styled.p`
  font-size: ${typography.text};
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
  margin: 37px auto 50px auto;
  max-width: 900px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textThin};
    margin: 14px auto 30px auto;
  }
`

export const QingyunContainer = styled.div`
  width: 100%;
  max-width: 1060px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
  margin: auto;
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

export const QingyunInfoContainer = styled.div`
  max-width: 475px;
  h2 {
    margin: ${spacing.base} 0;
    font-size: 40px;
    ${Media.lessThan(Media.small)} {
      font-size: ${typography.h2};
      margin: 0;
    }
  }
  p {
    font-size: ${typography.textSmall};
    font-weight: 400;
    color: #999999;
    ${Media.lessThan(Media.small)} {
      font-size: ${typography.textThin};
    }
  }
`
export const QingyunPriceText = styled.div`
  margin: ${spacing.large} 0;
  font-size: ${typography.h3};
  color: white;
  font-weight: bold;
  line-height: 1;
  strong {
    font-size: ${typography.title};
    color: ${color.primary};
  }
  ${Media.lessThan(Media.small)} {
    margin: ${spacing.small} 0 ${spacing.base};
    font-size: ${typography.h4};
    strong {
      font-size: ${typography.h2};
    }
  }
`
export const QingyunRound = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  margin-right: 70px;
  border-radius: 50%;
  background-color: ${color.primary};
  text-align: center;
  overflow: hidden;
  img {
    margin-top: ${spacing.large};
    width: 80px;
  }
  h5 {
    margin: ${spacing.small} 0 0;
    font-size: 75px;
    font-weight: bold;
    color: #ffffff;
    line-height: 1;
  }
  ${Media.lessThan(Media.small)} {
    width: 170px;
    height: 170px;
    margin: ${spacing.large} 0 0;
    img {
      margin-top: ${spacing.base};
      width: 46px;
    }
    h5 {
      margin-top: 5px;
      font-size: ${mbSize(70)};
    }
  }
`

export const QingyunRoundBottom = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 64px;
  line-height: 64px;
  text-align: center;
  background-color: white;
  color: #333;
  font-size: ${typography.h2};
  font-weight: bold;
  ${Media.lessThan(Media.small)} {
    height: 36px;
    line-height: 36px;
    font-size: ${typography.h4};
  }
`
export const VideoWrap = styled.div`
  video {
    width: 800px;
  }
  ${Media.lessThan(Media.small)} {
    video {
      width: 90vw;
    }
  }
`
