import React from 'react'

const SvgFilm12 = props => (
  <svg viewBox='0 0 46.58 50.02' {...props}>
    <defs>
      <style>
        {
          '.film_12_svg__cls-1,.film_12_svg__cls-2{fill:#13cb75}.film_12_svg__cls-2{fill-rule:evenodd}'
        }
      </style>
    </defs>
    <g id='film_12_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_12_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_12_svg__cls-1'
          d='M19.13 0a10.62 10.62 0 018.63 4.8 17.5 17.5 0 013 10.05v.73l-5.5.11v-.84a13.23 13.23 0 00-2.2-7.61 5.16 5.16 0 00-3.93-2.53 5.16 5.16 0 00-3.93 2.53 13.23 13.23 0 00-2.2 7.61v1.09l-2.4.05a8.51 8.51 0 00-3.05.62c-.06-.58-.08-1.17-.09-1.76a17.51 17.51 0 013-10A10.56 10.56 0 0119.13 0z'
        />
        <path
          className='film_12_svg__cls-2'
          d='M4.92 17.72l1-.1a8 8 0 00-3.13 6.2V42.5a6.79 6.79 0 00.77 3.15A4.41 4.41 0 010 41.24V23.09a5.52 5.52 0 014.92-5.37M10.94 18.55l20.11-.49c2.74-.07 5 1.76 5 4.45v9.18l-1.81.55a3.65 3.65 0 00-2.85 3.43v1.32a3.65 3.65 0 00-2.17 2l-.39.14-.07.17a3.58 3.58 0 00-.78.15h-.08l-.54.2-.13.06a1.59 1.59 0 00-.29.15 3.19 3.19 0 00-.67.19l-.68.25h-.09a5.12 5.12 0 00-.52.27 4 4 0 00-.81.21l-.42.16-.28.11-1.77.65-.41-.17-2.47.84-2.76 1-1.43 3.25-.91.27V47l-2.81.26a4.46 4.46 0 01-5-4.45v-18.9a5.43 5.43 0 015-5.36'
        />
        <path
          className='film_12_svg__cls-2'
          d='M42.94 38.5l1 3a.58.58 0 01-.39.73l-1 .32a.6.6 0 01-.74-.39l-1-3a.6.6 0 01.39-.74l1-.31a.6.6 0 01.75.38m-.41-5.09a21.31 21.31 0 014 12.66.6.6 0 01-.45.59l-6.61 2c-.54.17-.68.14-.89-.23-.48-.86-1.2-2.66-1.82-2.47l-.79.23c-.38.08-.55 0-.6-.22l-.14-.46c-.08-.21-.3-.26-.67-.13L19.75 50l-2-1 14.75-4.57a.64.64 0 00.44-.81.68.68 0 00-.83-.43l-14.44 4.43.76-1.73.5-.18 1 .27.33-.71.94-.32.88.35.06-.35 1-.35c.7.44 1.27.46 1.55-.43a.36.36 0 01.22-.22l.46-.17c.22-.09.28 0 .34 0a.57.57 0 00.92-.36c0-.07.07-.14.23-.22l.63-.23c.21-.09.31 0 .36 0 .31.35.65.22.67-.28 0 0 0-.12.14-.19l.35-.12a.25.25 0 01.24 0c1.26 1.21 1.64.36 2.12-.92l.81-.29c.17.15.29.17.31-.12l-.24-.8c-.06-.27.08-.36.42-.47l1.59-.49c.57-.47.29-2.27.36-3.58 0-.33.11-.43.61-.58l6.61-2a.64.64 0 01.69.28z'
        />
        <path
          className='film_12_svg__cls-1'
          d='M19.54 25.83a1.33 1.33 0 012.66 0 35.43 35.43 0 010 6.18 1.33 1.33 0 01-2.66 0 37.5 37.5 0 010-6.18'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm12
