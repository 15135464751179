import React from 'react'

const SvgFilm16 = props => (
  <svg viewBox='0 0 46 46' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M23 0A23 23 0 000 23a22.73 22.73 0 004.41 13.51.88.88 0 00.19.28A23 23 0 1023 0zM6.91 26.58a27.91 27.91 0 00-2.36 6.77 21.07 21.07 0 01-2.07-15.49 31.05 31.05 0 004 5.19 3.17 3.17 0 00-.34 1.44 3.23 3.23 0 00.77 2.09zm24.88-1.9a25.69 25.69 0 01-.3 3.86 3.24 3.24 0 00-2.55 2.37 29.3 29.3 0 01-16.49-5.18 3.21 3.21 0 00.26-1.24 3.27 3.27 0 00-.81-2.16 26 26 0 0114.8-8.77 3.27 3.27 0 003.35 1.83 25.89 25.89 0 011.74 9.29zm.32 5.63a1.44 1.44 0 11-1.45 1.44 1.44 1.44 0 011.45-1.44zm-2.46-16.74a1.45 1.45 0 111.45-1.44 1.45 1.45 0 01-1.45 1.44zM9.42 23A1.45 1.45 0 118 24.49 1.45 1.45 0 019.42 23zm-1 4.58a3.26 3.26 0 002.85-.45 31.24 31.24 0 0017.31 5.58 30.2 30.2 0 01-22.52 2.7 25.87 25.87 0 012.39-7.79zM33.9 29a3.19 3.19 0 00-.58-.3 26.82 26.82 0 00.31-4 27.55 27.55 0 00-1.9-10 3.26 3.26 0 001.06-1.57 26.17 26.17 0 019.84 2.36A30 30 0 0133.9 29zm-1.08-17.75A3.27 3.27 0 0028.73 9a27.56 27.56 0 00-7-7.1 21.29 21.29 0 0120 11.17 28.41 28.41 0 00-8.91-1.82zm-6.42.5a27.77 27.77 0 00-16.08 9.59 3.15 3.15 0 00-2.6.34 29.19 29.19 0 01-4.49-6.2A21.3 21.3 0 0118.86 2.25 26.1 26.1 0 0127.18 10a3.2 3.2 0 00-.78 1.75zm3.48 22.4l.07.06A26 26 0 0123 44.13a21.2 21.2 0 01-15.18-6.41 31.94 31.94 0 0022.06-3.57zm-4 9.81a27.38 27.38 0 005.78-9 4.22 4.22 0 00.49 0 3.27 3.27 0 003.26-2.93 31.51 31.51 0 007.77-2.63A21.3 21.3 0 0125.84 44zM44.16 23a20.47 20.47 0 01-.4 4.06 29.59 29.59 0 01-8.49 3.2 31.89 31.89 0 008.34-12 21.37 21.37 0 01.55 4.74z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgFilm16
