import React from 'react'

const SvgFilm15 = props => (
  <svg viewBox='0 0 40.11 47.98' {...props}>
    <defs>
      <style>{'.film_15_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='film_15_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_15_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_15_svg__cls-1'
          d='M16.57 3.69L14.81 4zm2.56-3.6L37.7 4.38a3.18 3.18 0 012 1.35A2.77 2.77 0 0140 8l-6.21 23.2a3.45 3.45 0 01-.25.63 3.24 3.24 0 01-3.61 1.52l-.73-.17a9.82 9.82 0 00-.46-1.69l1.58.37a1.62 1.62 0 001.57-.48 2.48 2.48 0 00.19-.26 1.88 1.88 0 00.12-.3L38.43 7.6a1.32 1.32 0 00-.18-1.08 1.57 1.57 0 00-1-.66L25 3a3.11 3.11 0 00-1-1 3.43 3.43 0 00-2.37-.54l-2.22.32-.71-.16a1.62 1.62 0 00-1.57.48l-1.92.28v-.09a2.6 2.6 0 01.25-.62 3 3 0 01.39-.55 3.33 3.33 0 013.22-1z'
        />
        <path
          className='film_15_svg__cls-1'
          d='M26.32 10l7.56 1.74a.58.58 0 01.44.69.61.61 0 01-.74.41l-7.06-1.63zM27 13.86l5.94 1.37a.56.56 0 01.44.69.6.6 0 01-.73.41l-5.45-1.26zM27.61 17.71l4.31 1a.58.58 0 01.44.69.62.62 0 01-.74.41l-3.8-.88zM2.76 4.13l18.91-2.72A3.43 3.43 0 0124 2a3 3 0 011.27 1.93l4 23.66a2.53 2.53 0 010 .67 2.43 2.43 0 01-.13.64 2.94 2.94 0 01-1 1.39 11 11 0 00-.87-1.34 1.49 1.49 0 00.29-.5 1.42 1.42 0 00.06-.31 1.17 1.17 0 000-.32l-3.94-23.7a1.45 1.45 0 00-.62-.94 1.63 1.63 0 00-1.15-.27L3 5.64A1.44 1.44 0 001.71 7a1.74 1.74 0 000 .32l4 23.66a1.49 1.49 0 001.04 1.12 1.27 1.27 0 00.34 0 1.29 1.29 0 00.34 0l1.9-.27A10.35 10.35 0 009 33.47l-1.28.18a3.47 3.47 0 01-.72 0 2.62 2.62 0 01-.68-.12 3 3 0 01-2.23-2.38L.1 7.52a2.53 2.53 0 010-.67 2.91 2.91 0 01.1-.65 3.12 3.12 0 012.56-2.06z'
        />
        <path
          className='film_15_svg__cls-1'
          d='M6.26 12.82a.6.6 0 01-.69-.47.57.57 0 01.51-.65L21.2 9.53a.6.6 0 01.69.47.56.56 0 01-.51.64zM6.85 16.43a.61.61 0 01-.7-.47.57.57 0 01.51-.64l15.12-2.18a.61.61 0 01.7.47.58.58 0 01-.51.65zM7.38 20a.6.6 0 01-.69-.47.57.57 0 01.5-.65l15.12-2.18a.6.6 0 01.7.47.57.57 0 01-.51.64zM5.89 48A6.17 6.17 0 010 41.58a6.17 6.17 0 015.89-6.41h3A10.16 10.16 0 0019.11 45a10.15 10.15 0 0010.24-9.8h3.46a6.16 6.16 0 015.88 6.41A6.16 6.16 0 0132.81 48z'
        />
        <path
          d='M19.11 27.34a7.6 7.6 0 11-7.77 7.59 7.68 7.68 0 017.77-7.59'
          fillRule='evenodd'
          fill='#13cb75'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm15
