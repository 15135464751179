import React from 'react'

const SvgFilm10 = props => (
  <svg viewBox='0 0 114 80' {...props}>
    <defs>
      <style>{'.film_10_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='film_10_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_10_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_10_svg__cls-1'
          d='M114 18.52a3.34 3.34 0 00-3.26-3.32h-10.27v6.63h10.22a3.23 3.23 0 003.31-3.31zm-3.2 11h-10.33v6.58h10.22a3.29 3.29 0 00.06-6.58zm0 14.32h-10.33v6.58h10.22a3.3 3.3 0 003.2-3.37 3.19 3.19 0 00-3.14-3.21zm0 14.27h-10.33v6.58h10.22a3.29 3.29 0 00.06-6.58zM.94 16.24A3.4 3.4 0 001.11 21a3.26 3.26 0 002.15.87h10.28v-6.62H3.26a3.15 3.15 0 00-2.32 1zM0 32.9a3.34 3.34 0 003.25 3.32h10.29v-6.64H3.26A3.25 3.25 0 000 32.84zm.88 11.93A3.16 3.16 0 000 47.16a3.25 3.25 0 003.25 3.26h10.23v-6.58H3.26a3.39 3.39 0 00-2.38 1zM.94 59.1a3.39 3.39 0 00.17 4.77 3.22 3.22 0 002.15.88h10.22v-6.64H3.2a3.12 3.12 0 00-2.26 1zM60.06 48.66l-12.62 9.19a7.76 7.76 0 11-7.12-4.8 7.7 7.7 0 012.8.58l14.25-10.42a3.1 3.1 0 011.75-.52h37.65V9.61A9.61 9.61 0 0087.19 0H26.56A9.61 9.61 0 0017 9.61v23.65h36.63l12.62-9.19a7.76 7.76 0 117.12 4.8 7.7 7.7 0 01-2.8-.58L56.32 38.71a3.38 3.38 0 01-1.69.47H17v31.21A9.61 9.61 0 0026.56 80h60.63a9.61 9.61 0 009.58-9.61V48.66z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm10
