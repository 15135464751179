import React from 'react'

const SvgFilm02 = props => (
  <svg viewBox='0 0 72.39 62' {...props}>
    <defs>
      <style>{'.film_02_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='film_02_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_02_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_02_svg__cls-1'
          d='M39.76 62H17.68a2.09 2.09 0 01-.36 0 18.25 18.25 0 010-36.45 1.34 1.34 0 01.35 0H20A25.55 25.55 0 0166.53 11 1.55 1.55 0 0164 12.78a22.45 22.45 0 00-40.93 12.76v1.38a1.54 1.54 0 01-.41 1.16 1.58 1.58 0 01-1.13.49H17.76a15.14 15.14 0 000 30.27h22a1.55 1.55 0 110 3.1z'
        />
        <path
          className='film_02_svg__cls-1'
          d='M71.75 46.72l-3.47-2h-.08a1.29 1.29 0 00-1.77.46 14 14 0 11-.13-14.5.35.35 0 000 .11 1.29 1.29 0 001.12.65 1.4 1.4 0 00.65-.17l3.64-2.11a1.29 1.29 0 10-1.29-2.24l-2.61 1.51a16.55 16.55 0 00-4.4-4.31l1.59-2.7a1.29 1.29 0 10-2.24-1.29l-1.57 2.7a16.49 16.49 0 00-5.93-1.44V18.6a1.29 1.29 0 10-2.58 0v2.85a17 17 0 00-4.18 1 16 16 0 00-1.55.77l-1.77-3.06a1.29 1.29 0 10-2.24 1.29l1.79 3.09a16.21 16.21 0 00-4.13 4.21l-3.15-1.81a1.28 1.28 0 00-1.76.47 1.29 1.29 0 00.47 1.77L39.28 31c0 .05-.06.09-.08.14a16.75 16.75 0 00-1.42 5.49h-3.63a1.29 1.29 0 000 2.58h3.61a16.71 16.71 0 001 4.64c.14.38.36.71.53 1.08l-3.18 1.83a1.29 1.29 0 00-.47 1.77 1.27 1.27 0 001.12.65 1.21 1.21 0 00.64-.18l3.12-1.8a16.41 16.41 0 004.1 4.29l-1.73 3a1.3 1.3 0 00.47 1.77 1.34 1.34 0 00.65.17 1.28 1.28 0 001.12-.65l1.73-3c.2.11.38.25.59.34a16.71 16.71 0 005.18 1.4v2.79a1.29 1.29 0 102.58 0v-2.8a16.46 16.46 0 006-1.45l1.55 2.68a1.29 1.29 0 001.76.48 1.3 1.3 0 00.48-1.77l-1.53-2.64a16.46 16.46 0 004.44-4.33L70.45 49a1.25 1.25 0 00.65.18 1.3 1.3 0 00.65-2.42z'
        />
        <path
          className='film_02_svg__cls-1'
          d='M54.46 44.41A6.46 6.46 0 1160.91 38a6.46 6.46 0 01-6.45 6.41zm0-9.82A3.36 3.36 0 1057.81 38a3.36 3.36 0 00-3.35-3.41z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm02
