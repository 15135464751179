import React from 'react'

const SvgFilm18 = props => (
  <svg viewBox='0 0 40.29 45.91' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M34.25 2.58C30.72.86 25.69 0 20.15 0S9.57.86 6 2.58c-4 2-6 4.31-6 7.18v26.68c0 2.56 2 5.17 6 6.89 3.53 1.72 8.57 2.58 14.11 2.58s10.57-.86 14.1-2.58c3.77-1.72 6-4.3 6-6.89V9.76c0-2.87-2-5.17-6-7.18M32 14.35a31.52 31.52 0 01-11.83 2 30.64 30.64 0 01-11.84-2c-3.8-1.15-5.56-2.87-5.56-4.59s2-3.45 5.54-4.88a30.46 30.46 0 0111.84-2 38.3 38.3 0 0111.85 2c3.51 1.43 5.52 3.12 5.52 4.88S35.76 13.2 32 14.35m.25 8.32a38.6 38.6 0 01-12.08 2 39.9 39.9 0 01-12.09-2c-3.53-1.43-5.54-3.16-5.54-4.88v-3.15C5 15.78 6.8 17.22 9.57 17.79a41.41 41.41 0 0010.58 1.44 41.39 41.39 0 0010.57-1.44c2.77-.57 4.53-2 7.05-3.15v3.44c0 1.43-2 3.16-5.54 4.59m0 9.18a38.6 38.6 0 01-12.08 2 39.9 39.9 0 01-12.09-2C4.53 30.71 2.52 29 2.52 27v-4.3a32.63 32.63 0 007.05 3.44 41.41 41.41 0 0010.58 1.44 41.39 41.39 0 0010.57-1.44 32.63 32.63 0 007.05-3.44V27c0 2-2 3.74-5.54 4.88M20.15 43.05A40.21 40.21 0 018.06 41c-3.53-1.15-5.54-2.87-5.54-4.88v-4.27a32.67 32.67 0 007.05 3.45 39.45 39.45 0 0010.32 1.43 41.41 41.41 0 0010.58-1.43 29.57 29.57 0 007.3-3.45v4.31c0 1.72-2 3.44-5.54 4.88-3.27 1.43-8.06 2-12.08 2'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgFilm18
