import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transition } from '@renderbus/common/theme'

const SwiperContainer = styled.div`
  position: relative;
  padding: 50px 0;
  overflow: hidden;
`
const SwiperWrapper = styled.div`
  display: flex;
  transform: ${p => {
    return `translate3d(${p.translateSize}%, 0px, 0px)`
  }};
  transition: ${transition('transform', 300)};
  user-select: none;
`
const SwiperItem = styled.div`
  width: ${p => `${100 / p.slidesPerView}%`};
  flex-shrink: 0;
  opacity: ${p => (p.isActive ? '1' : '0.6')};
  transform: ${p => p.isActive && 'scale(1.2)'};
  z-index: ${p => p.isActive && '1'};
  transition: ${transition(['opacity', 'transform'])};
`
const ClickArea = styled.div`
  position: absolute;
  top: 0;
  left: ${p => p.type === 'left' && 0};
  right: ${p => p.type === 'right' && 0};
  height: 100%;
  width: 20%;
  cursor: pointer;
`

class CustomizeSwiper extends React.PureComponent {
  state = { index: this.props.initialSlide }
  get translateSize() {
    const { slidesPerView, centeredSlides } = this.props
    const { index } = this.state
    if (centeredSlides) {
      return ((0.5 - index) * 100) / slidesPerView
    } else {
      return ((1 - index) * 100) / slidesPerView
    }
  }
  handlerSlideChange(direction) {
    let index = this.state.index
    let lastIndex = this.props.children.length - 1
    if (direction) {
      if (index >= lastIndex) {
        index = 0
      } else {
        index++
      }
    } else {
      if (index <= 0) {
        index = lastIndex
      } else {
        index--
      }
    }
    this.setState({ index: index })
    this.props.onSlideChange(index)
  }
  render() {
    const { children, slidesPerView } = this.props
    const { index } = this.state
    return (
      <div>
        <SwiperContainer>
          <SwiperWrapper index={index || 0} translateSize={this.translateSize}>
            {children.map((child, i) => {
              return (
                <SwiperItem slidesPerView={slidesPerView} key={i} isActive={index === i}>
                  {child}
                </SwiperItem>
              )
            })}
          </SwiperWrapper>
          <ClickArea
            type='left'
            onClick={() => {
              this.handlerSlideChange(false)
            }}
          />
          <ClickArea
            type='right'
            onClick={() => {
              this.handlerSlideChange(true)
            }}
          />
        </SwiperContainer>
      </div>
    )
  }
}

CustomizeSwiper.propTypes = {
  initialSlide: PropTypes.number,
  slidesPerView: PropTypes.number,
  onSlideChange: PropTypes.func.isRequired,
  centeredSlides: PropTypes.bool,
}
CustomizeSwiper.defaultProps = {
  initialSlide: 0,
  slidesPerView: 1,
}

export default CustomizeSwiper
