import React from 'react'
import Swiper from './customize-swiper'

import PropTypes from 'prop-types'

class ScaleSwiper extends React.PureComponent {
  getSwiper(index) {
    this.props.onSlideChange(index)
  }
  render() {
    const { children, initialSlide } = this.props
    return (
      <div>
        <Swiper
          slidesPerView={2}
          centeredSlides={true}
          initialSlide={initialSlide || 0}
          onSlideChange={s => this.getSwiper(s)}
        >
          {children}
        </Swiper>
      </div>
    )
  }
}

ScaleSwiper.propTypes = {
  initialSlide: PropTypes.number,
  onSlideChange: PropTypes.func.isRequired,
}
ScaleSwiper.defaultProps = {
  initialSlide: 0,
}

export default ScaleSwiper
