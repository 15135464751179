import React from 'react'

const SvgFilm08 = props => (
  <svg viewBox='0 0 40 40' {...props}>
    <g data-name='\u56FE\u5C42 2'>
      <path
        d='M20 0a20 20 0 1020 20A20 20 0 0020 0zm-8 9.07A12.9 12.9 0 0116.53 8a9.72 9.72 0 012 .27 10.59 10.59 0 012.8 1.33l-2.4 8.27a8.58 8.58 0 00-4.8-1.6 12.4 12.4 0 00-4.53.93zm-1.07 18.26a14.34 14.34 0 00-4.4.94l2.4-8.14c.14-.13.4-.13.67-.26a19.42 19.42 0 013.73-.8h.54a7.6 7.6 0 013.6.93c.26.13.66.4.93.53-.53 1.47-1.87 6.4-2.53 8.4a9.06 9.06 0 00-4.94-1.6zM28 30.93a13.77 13.77 0 01-5.07 1.2A11.71 11.71 0 0118 30.4l2.4-8.27a9.5 9.5 0 005.47 1.6 13.84 13.84 0 004.4-.8zM31.2 20h-.13a12.36 12.36 0 01-4.67 1.07 9.24 9.24 0 01-5.2-1.6l2.4-8.4a9.36 9.36 0 005.33 1.6 14.33 14.33 0 004.54-.94z'
        fill='#13cb75'
        data-name='\u56FE\u5C42 1'
      />
    </g>
  </svg>
)

export default SvgFilm08
