import React from 'react'

const SvgFilm05 = props => (
  <svg viewBox='0 0 40 40' {...props}>
    <defs>
      <style>{'.film_05_svg__cls-1{fill:#13cb75}'}</style>
    </defs>
    <g id='film_05_svg__\u56FE\u5C42_2' data-name='\u56FE\u5C42 2'>
      <g id='film_05_svg__\u56FE\u5C42_1-2' data-name='\u56FE\u5C42 1'>
        <path
          className='film_05_svg__cls-1'
          d='M22.86 0H0v13.33h22.86zM21 11.43H1.9V1.9H21zM17.14 40H40V26.67H17.14zm1.91-11.43H38.1v9.53h-19zM26.67 0v22.86H40V0zM38.1 21h-9.53V1.9h9.53zM0 40h13.33V17.14H0zm1.9-20.95h9.53V38.1H1.9z'
        />
      </g>
    </g>
  </svg>
)

export default SvgFilm05
